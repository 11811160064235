<template>
  <v-footer color="primary" class="white--text mt-6">
    <v-container >
      <v-row>
        <v-col>
          <h4 class="subheader">Useful Links</h4>
          <ul>
            <li><a class="white--text" href="/about">About Us</a></li>
            <li><a class="white--text" href="/legal#terms">Terms of Use</a></li>
            <li><a class="white--text" href="/legal#privacy">Privacy Policy</a></li>
          </ul>
        </v-col>
        <v-col xs="6" sm="6" md="4">
          <h4>Follow us</h4>
          <v-btn icon color="white" href="https://www.facebook.com/goNorthSulawesi/"><v-icon>mdi-facebook</v-icon></v-btn>
          <v-btn icon color="white" href="https://instagram.com/amborjo_"><v-icon>mdi-instagram</v-icon></v-btn>
          <v-btn icon color="white" href="https://www.youtube.com/channel/UCXV44QhzQPnK-4371VulroQ"><v-icon>mdi-youtube</v-icon></v-btn>
          <v-btn icon color="white" href="https://id.pinterest.com/gonorthsulawesi"><v-icon>mdi-pinterest</v-icon></v-btn>
        </v-col>
      </v-row>
      <v-row><small>&copy; 2020 AmborJo All Rights Reserved</small></v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>
