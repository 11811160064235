<template>
  <div class="d-flex flex-column">
    <jumbotron :aspect-ratio="isOnPC ? undefined : 1.6" src="/img/backgrounds/home-background.jpg">
      <v-container class="fill-height pa-0 white--text">
        <v-row justify="stretch" align="center" class="fill-height" no-gutters>
          <v-col>
            <div class="shadow">
              <h1
              class="text-center"
              style="font-family: 'Lobster', cursive !important"
              :class="isOnPC ? 'display-3' : 'display-1' "
              >
                Welcome to AmborJo
              </h1>
              <p class="text-center subtitle-1">
                <span v-if="isOnPC">Experience everything North Sulawesi has to offer.</span>
                <small v-else>Experience everything North Sulawesi has to offer.</small>
              </p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </jumbotron>
    <newest-restaurants />
    <newest-articles />
    <suggestions />
    <instagram-feed />
    <my-footer />
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import Jumbotron from '../components/Jumbotron'
import HaveYouVisited from '../components/FrontPage/HaveYouVisited'
import NewestArticles from '../components/FrontPage/NewestArticles'
import Instagram from '../components/Instagram'
import Footer from '../components/Footer'
import NewestRestaurantSegment from '../components/FrontPage/NewestRestaurantSegment/NewestRestaurants'

export default {
  name: 'Home',
  components: {
    jumbotron: Jumbotron,
    'my-footer': Footer,
    suggestions: HaveYouVisited,
    'newest-articles': NewestArticles,
    'instagram-feed': Instagram,
    'newest-restaurants': NewestRestaurantSegment
  },

  computed: {
    isOnPC () {
      return this.$vuetify.breakpoint.mdAndUp
    }
  },

  beforeCreate () {
    document.title = 'Welcome to AmborJo'
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');

.shadow {
  text-shadow: 5px 5px 25px rgba(0, 0, 0, 0.75);
}

</style>
