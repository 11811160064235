<template>
  <v-container class="my-2">
    <v-row>
      <h2 class="headline">Latest Articles</h2>
    </v-row>
    <v-row>
      <v-col cols="12" xs="12" sm="6" md="6" v-for="(article, n) in articles" :key="`${n}${JSON.stringify(article)}`">
        <v-card width="100%" :to="`/articles/${article.id}/${article.url ? article.url : ''}`">
          <v-img :aspect-ratio="1.67" :src="article.mainImage" class="white--text align-end">
            <template v-slot:placeholder>
              <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
              >
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
              </v-row>
            </template>
            <div class="lightbox">
              <v-card-title class="py-1">{{ article.title }}</v-card-title>
              <v-card-text v-if="article.summary">{{ article.summary }}</v-card-text>
            </div>
          </v-img>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import firebase from '../../plugins/firebase'

export default {
  data () {
    return ({
      articles: [],
      loading: false
    })
  },

  async created () {
    this.$data.loading = true
    this.$data.articles = await this.getArticles()
    this.$data.loading = false
  },

  methods: {
    getArticles () {
      const db = firebase.firestore()

      return new Promise((resolve, reject) => {
        db.collection('/articles').orderBy('dateModified', 'desc').limit(4).get().then(collection => {
          var o = collection.docs.map(doc => {
            return Object.assign(doc.data(), { id: doc.id })
          })

          resolve(o)
        }).catch(e => { reject(e) })
      })
    }
  }
}
</script>

<style scoped>
.lightbox {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75));
}
</style>
