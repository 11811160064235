<template>
  <v-card width="100%" :to="`/restaurants/${restaurant.id}/${restaurant.url}`">
    <v-img aspect-ratio="1" class="white--text align-end" :src="restaurant.primaryPhoto">
      <div class="lightbox">
        <v-card-title class="py-1 text-truncate">{{ restaurant.name }}</v-card-title>
        <v-card-text>{{ restaurant.description }}</v-card-text>
      </div>
    </v-img>
  </v-card>
</template>

<script>
export default {
  name: 'NewestRestaurantCard',

  props: {
    restaurant: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>
.lightbox {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75))
}
</style>
