<template>
  <v-container>
    <v-row><h2 class="headline">Newest Restaurants</h2></v-row>
    <v-row>
      <v-col
        cols="6"
        xs="12"
        sm="6"
        md="4"
        lg="3"
        xl="2"
        v-for="restaurant in restaurants"
        :key="restaurant.id"
      >
        <restaurant-card :restaurant="restaurant" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import firebase from '../../../plugins/firebase'
import NewestRestaurantCard from './NewestRestaurantCard'

export default {
  name: 'NewestRestaurantsHomepageSegment',

  components: {
    'restaurant-card': NewestRestaurantCard
  },

  data () {
    return ({
      restaurants: [],
      loading: false
    })
  },

  methods: {
    getRestaurants () {
      return new Promise((resolve, reject) => {
        var restaurantCollection = firebase.firestore().collection('/restaurants')
          .where('published', '==', true)
          .where('toBeDeleted', '==', false)
          .orderBy('dateAdded', 'desc')
          .orderBy('rating', 'desc')
          .limit(8)

        restaurantCollection.get()
          .then(querySnapshot => {
            var resolveValue = []
            querySnapshot.docs.forEach(doc => {
              resolveValue.push(Object.assign({ id: doc.id }, doc.data()))
            })
            resolve(resolveValue)
          })
          .catch(e => { reject(e) })
      })
    }
  },

  async mounted () {
    this.$data.loading = true

    try { this.$data.restaurants = await this.getRestaurants() } catch (e) {
      this.$store.commit('setError', 'Cannot get newest restaurants.')
      console.error(e)
    }

    this.$data.loading = false
  }
}
</script>
