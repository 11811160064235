<template>
  <v-container>
    <v-row>
      <h2 class="headline">Instagram</h2>
    </v-row>
    <v-row>
      <v-col cols="6" sm="4" md="3" xl="2" v-for="photo in photos" :key="photo.id">
        <v-hover v-slot:default="{ hover }">
          <v-card :href="photo.url" :elevation="hover ? 16 : undefined">
            <v-img aspect-ratio="1" contain :src="photo.thumbnail ? photo.thumbnail : photo.mediaUrl">
              <v-fade-transition>
                <v-sheet v-if="hover" height="100%" color="rgba(0, 0, 0, 0.5)">
                  <v-card-text class="white--text" v-text="photo.caption" />
                </v-sheet>
              </v-fade-transition>
            </v-img>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
    <v-row align="center" justify="center" class="mt-2">
      <v-btn color="primary" href="https://instagram.com/amborjo_">Visit our Instagram</v-btn>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'Instagram',

  data () {
    return ({
      photos: [],
      loading: false
    })
  },

  computed: {
    IG_ACCESS_TOKEN () {
      return process.env.VUE_APP_INSTAGRAM_ACCESS_TOKEN
    }
  },

  async created () {
    this.$data.loading = true
    var igResponse = await this.getInstagramPhotos()

    this.$data.photos = igResponse.data.map(post => ({
      thumbnail: post.thumbnail_url,
      caption: post.caption,
      id: post.id,
      mediaType: post.media_type,
      mediaUrl: post.media_url,
      url: post.permalink
    })).slice(0, 8)

    this.$data.loading = false
  },

  methods: {
    getInstagramPhotos () {
      // const IG_ACCESS_TOKEN = '6085659059.1677ed0.cf6628917775434a92a2f88753bc5b80'
      // const IG_USER_ID = '6085659059'

      const IG_ACCESS_TOKEN = this.IG_ACCESS_TOKEN

      const IG_ENDPOINT_URL = new URL('https://graph.instagram.com/me/media')
      IG_ENDPOINT_URL.searchParams.append('fields', 'caption,id,media_type,permalink,thumbnail_url,media_url,timestamp')
      IG_ENDPOINT_URL.searchParams.append('access_token', IG_ACCESS_TOKEN)

      return window.fetch(IG_ENDPOINT_URL.toString()).then(res => res.json())
    }
  }
}
</script>
