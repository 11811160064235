<template>
  <v-container class="my-2">
    <v-row>
     <h2 class="headline">Have you visited...</h2>
    </v-row>
    <v-row>
      <v-col :cols="12">
        <v-slide-group center-active>
          <v-slide-item v-for="(slide, n) in slides" :key="n" class="mx-1" v-slot:default="{ toggle }">
            <v-skeleton-loader :loading="loading" type="card">
              <v-card @click="toggle" height="100%" :to="`/destinations/${slide.id}/${slide.url ? slide.url : ''}`">
                <v-responsive :aspect-ratio="16 / 10" :width="!isOnPC ? 270 : 375" height="100%">
                  <v-img class="white--text align-end" height="100%" gradient="to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7)" :src="slide.thumbnailURL ? slide.thumbnailURL : '/img/default-thumbnail.png'">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular indeterminate color="primary"></v-progress-circular>
                      </v-row>
                    </template>
                    <v-card-title>
                      {{slide.name}}
                    </v-card-title>
                  </v-img>
                </v-responsive>
              </v-card>
            </v-skeleton-loader>
          </v-slide-item>
        </v-slide-group>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import firebase from '../../plugins/firebase'

export default {
  name: 'HaveYouVisited',

  data () {
    return ({
      slides: [],
      loading: true
    })
  },

  computed: {
    isOnPC () {
      return this.$vuetify.breakpoint.mdAndUp
    }
  },

  methods: {
    getDestinations () {
      const db = firebase.firestore()
      return new Promise((resolve, reject) => {
        db.collection('/destinations').limit(5).get().then(querySnapshot => {
          resolve(querySnapshot.docs.map(doc => Object.assign(
            {},
            { id: doc.id },
            doc.data()
          )))
        })
      })
    }
  },

  async mounted () {
    this.$data.loading = true
    this.$data.slides = await this.getDestinations()
    this.$data.loading = false
  }
}
</script>
